import { FC } from 'react';
import { Box } from '@mui/material';
import { IDevProject } from 'modules/theme/components/listing/cards/promoDevProjectCard/interfaces/IDevProject';
import { Photo } from 'modules/theme/components/listing/cards/promoDevProjectCard/components/card/partials/photo/Photo';
import {
    Wrapper
} from 'modules/theme/components/listing/cards/promoDevProjectCard/components/card/partials/wrapper/Wrapper';
import {
    Content
} from 'modules/theme/components/listing/cards/promoDevProjectCard/components/card/partials/content/Content';

interface IPromoDevProjectCard {
    devProject: IDevProject;
    onProjectClick?: () => void;
    devProjectDetailUrl?: string;
}

export const PromoDevProjectCard: FC<IPromoDevProjectCard> = ({
    devProject,
    devProjectDetailUrl,
    onProjectClick
}) => {
    const {
        imageUrl,
        location,
        projectName,
        advertisementsCategories,
        totalAdvertisementsCount,
        price: { price, unitPrice },
        packages: { isTop, isPremium },
    } = devProject;

    return <Wrapper href={devProjectDetailUrl} onWrapperClick={onProjectClick}>

        <Box position='relative' height='100%' tabIndex={-1}>
            <Content
                isTop={isTop}
                price={price}
                location={location}
                isPremium={isPremium}
                unitPrice={unitPrice}
                projectName={projectName}
                advertisementsCategories={advertisementsCategories}
                totalAdvertisementsCount={totalAdvertisementsCount}
            />

            <Photo photoUrl={imageUrl}/>
        </Box>

    </Wrapper>;
};
