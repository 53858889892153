import { Box } from '@mui/system';
import React, { FC } from 'react';
import WelcomeImage from 'components/page/homePage/components/becomeMember/assets/WelcomeImage.png';
import Image from 'components/image/Image';
import Link from 'components/link/Link';
import { Text } from 'modules/theme/components/text/Text';
import { MY_ACCOUNT } from 'modules/route/routes';
import { spinClockwiseKeyframes, sunriseRightDesktopKeyframes } from 'components/page/homePage/components/becomeMember/constants/Keyframes';
import SunImage from 'components/page/homePage/components/becomeMember/assets/Sun.svg';
import { getClickOnUserNameEvent } from 'modules/gtmEvents/helpers/events/homePage/homePageEventsHelper';
import useAnalytics from 'modules/stores/analytics/useAnalytics';

interface IProps {
    name: string
}

export const DesktopLoggedInBecomeMember: FC<IProps> = ({ name }) => {
    const {
        gtm: { sendEvent: gtmSendEvent },
    } = useAnalytics();

    return (
        <Box display='flex' justifyContent='center' gap={4} marginBottom={5}>
            <Box>
                <Box position='absolute' sx={{
                    animation: `${sunriseRightDesktopKeyframes} 0.5s , ${spinClockwiseKeyframes} 1.25s infinite`,
                    animationDelay: '2s',
                    animationFillMode: 'forwards',
                    width: 50,
                    height: 50,
                    marginLeft: 5,
                    marginTop: 5,
                    zIndex: -1,
                }}>
                    <SunImage width={50} height={50} />
                </Box>
                <Image
                    src={WelcomeImage}
                    alt='welcomeImage'
                    style={{ objectFit: 'contain' }}
                />
            </Box>
            <Box display='flex' flexDirection='column'>
                <Box display='flex' flexDirection='row' marginBottom={1} marginTop={2}>
                    <Text variant='h4' semibold>
                        Dobrý deň,&nbsp;
                    </Text>
                    <Link href={MY_ACCOUNT} onClick={() => gtmSendEvent(getClickOnUserNameEvent())}>
                        <Text variant='h4' semibold underLine>
                            {name}
                        </Text>
                    </Link>
                </Box>
                <Text variant='body2'>
                    Ďakujeme, že sme váš obľúbený realitný portál.
                </Text>
                <Text variant='body2' semibold>
                    Aké možnosti dnes využijete?
                </Text>
            </Box>
        </Box>
    );
};
