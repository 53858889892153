'use client';

import { FC } from 'react';
import { AdditionalServicesList } from 'components/additionalServicesList/AdditionalServicesList';
import { additionalServices } from 'components/page/homePage/components/becomeMember/constants/AdditionalServices';

export const Services: FC = () => {

    return <AdditionalServicesList
        additionalServices={additionalServices}
    />;
};