import { FC } from 'react';
import { Text } from 'modules/theme/components/text/Text';
import { Box } from '@mui/system';

interface ITitle {
    text: string;
    onTitleClick: () => void
}

export const Title: FC<ITitle> = ({ text, onTitleClick }) => {
    return (
        <Box sx={{ cursor: 'pointer' }} onClick={onTitleClick}>
            <Text variant='h5' semibold>
                {text}
            </Text>
        </Box>
    );
};
