'use client';

import { FC, useMemo } from 'react';
import useSession from 'modules/stores/session/useSession';
import { Box, Container, useMediaQuery, Theme } from '@mui/material';
import { Desktop, Mobile } from 'modules/theme/components/responsive';
import { DesktopBecomeMember } from 'components/page/homePage/components/becomeMember/desktop/DesktopBecomeMember';
import { MobileBecomeMember } from 'components/page/homePage/components/becomeMember/mobile/MobileBecomeMember';
import { DesktopLoggedInBecomeMember } from 'components/page/homePage/components/becomeMember/desktop/DesktopLoggedInBecomeMember';
import { MobileLoggedInBecomeMember } from 'components/page/homePage/components/becomeMember/mobile/MobileLoggedInBecomeMember';

export const BecomeMember: FC = () => {
    const { session } = useSession();
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('md')
    );

    const isLoggedIn = !!session;

    const parsedUserName = useMemo((): string => {
        if (!session?.nick) return '';
        if (!session.nick.includes(' ')) return session.nick;
        return session.nick.split(' ')[0];
    }, [session?.nick]);

    return (
        <Container disableGutters={isMobile}>
            <Box
                paddingLeft={0}
                paddingRight={0}
            >
                <Mobile>
                    {isLoggedIn ? (
                        <MobileLoggedInBecomeMember name={parsedUserName} />
                    ) : (
                        <MobileBecomeMember />
                    )}
                </Mobile>
                <Desktop>
                    {isLoggedIn ? (
                        <DesktopLoggedInBecomeMember name={parsedUserName} />
                    ) : (
                        <DesktopBecomeMember />
                    )}
                </Desktop>
            </Box>
        </Container>
    );
};
