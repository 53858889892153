'use client';

import { FC } from 'react';
import Link from 'components/link/Link';
import { Box, Stack, useTheme } from '@mui/material';
import { ADVERTISEMENT_SEARCH } from 'modules/route/routes';
import { Text } from 'modules/theme/components/text/Text';
import { useLoginStore } from 'modules/stores/login/useLoginStore';
import useAnalytics from 'modules/stores/analytics/useAnalytics';
import { getClickOnPopularRegionsSKEvent } from 'modules/gtmEvents/helpers/events/homePage/homePageEventsHelper';
import { IPopularRegion } from 'components/page/homePage/components/popularRegions/common/interfaces/IPopularRegion';

interface IProps {
    item: IPopularRegion;
}

export const PopularRegionItem: FC<IProps> = ({ item }) => {
    const theme = useTheme();

    const {
        gtm: { sendEvent: gtmSendEvent },
    } = useAnalytics();

    const isUserLoggedIn = useLoginStore((store) => store.isUserLoggedIn);

    return (
        <Link
            href={ADVERTISEMENT_SEARCH(item.sef)}
            noUnderline
            onClick={() => {
                gtmSendEvent(
                    getClickOnPopularRegionsSKEvent(item.sef, isUserLoggedIn)
                );
            }}
        >
            <Stack direction='row' gap={1.5} alignItems='center'>
                <Box
                    width={40}
                    height={32}
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    border={`1px solid ${theme.palette.dividerTransparent}`}
                    borderRadius={0.5}
                >
                    <Text
                        variant='body2'
                        semibold
                        color={(palette) => palette.labelSecondary}
                    >
                        {item.abbreviation}
                    </Text>
                </Box>
                <Text noWrap variant='body2' underLine>
                    {item.linkText}
                </Text>
            </Stack>
        </Link>
    );
};
