'use client';

import { FC } from 'react';
import { usePageViewEvent } from 'modules/gtmEvents/hooks/pageView/usePageViewEvent';


export const GtmEvents: FC = () => {
    usePageViewEvent();

    return null;
};