'use client';

import { FC } from 'react';
import { Text } from 'modules/theme/components/text/Text';

export const Title: FC = () => {
    return (
        <Text variant='h3' mobileVariant='h4' semibold noWrap>
            Aké bývanie hľadáte?
        </Text>
    );
};
