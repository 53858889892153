import { FC, FunctionComponent, SVGProps } from 'react';
import { Box } from '@mui/material';
import Link from 'components/link/Link';
import { Svg } from 'modules/theme/components/svg/Svg';
import { useLoginStore } from 'modules/stores/login/useLoginStore';
import { Desktop, Mobile } from 'modules/theme/components/responsive';
import useAnalytics from 'modules/stores/analytics/useAnalytics';
import { ESefCategories } from 'components/page/homePage/enums/categorySefs/ESefCategories';
import { getClickOnCategoryEvent } from 'modules/gtmEvents/helpers/events/homePage/homePageEventsHelper';
import { Title } from 'components/page/homePage/components/whatAreYouLookingFor/partials/iconsBoxes/partials/iconBox/partials/title/Title';

interface IIcon {
    label: string;
    link?: string;
    sef: ESefCategories;
    icon: FunctionComponent<SVGProps<SVGSVGElement>>;
    openModal?: (label: string) => void;
    mainLink?: string
}

export const IconBox: FC<IIcon> = ({
    icon,
    label,
    link,
    sef,
    openModal,
    mainLink
}) => {

    const {
        gtm: { sendEvent: gtmSendEvent },
    } = useAnalytics();

    const isUserLoggedIn = useLoginStore((store) => store.isUserLoggedIn);

    return (
        <>
            <Link
                noUnderline
                href={link}
                onClick={() => {
                    gtmSendEvent(
                        getClickOnCategoryEvent(isUserLoggedIn, sef)
                    );

                    if (mainLink) openModal(label);
                }}
            >
                <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                    flexDirection='column'
                    paddingBottom={{
                        xs: 2,
                        md: 3,
                    }}
                    paddingTop={{
                        xs: 2,
                        md: 3,
                    }}
                    sx={{
                        cursor: 'pointer',
                        '&:hover': {
                            textDecoration: 'underline',
                            textUnderlineOffset: 6
                        }
                    }}
                >
                    <Mobile>
                        <Svg icon component={icon} width={160} height={60} />
                    </Mobile>
                    <Desktop>
                        <Svg icon component={icon} width={160} height={80} />
                    </Desktop>
                    <Box sx={{
                        mt: { xs: .5, md: 1 },
                    }}>
                        <Title text={label} />
                    </Box>
                </Box>
            </Link>
        </>
    );
};
