'use client';

import { FC } from 'react';
import { Box } from '@mui/material';
import { Ad } from 'components/ads/components/ad/Ad';
import { AdSlot } from 'components/ads/enums/AdSlot';
import { useDetectAdBlock } from 'adblock-detect-react';
import useCookieBar from 'modules/stores/cookieBar/useCookieBar';
import { AdsDivider } from 'components/page/homePage/components/ads/partials/AdsDivider';

export const Ads: FC = () => {
    const { isAllowed } = useCookieBar();
    const adBlockDetected = useDetectAdBlock();

    if (adBlockDetected || !isAllowed.googleDoubleClick) {
        return null;
    }

    return (
        <Box>
            <AdsDivider />
            <Box
                pb={{
                    xs: 6,
                    md: 8,
                }}
            >
                <Ad slotMobile={AdSlot.AD_MOBILE_TOP} />
            </Box>
        </Box>
    );
};
