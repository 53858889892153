'use client';

import { FC } from 'react';
import { Box } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';
import { lemmatizeWordAccordingToNumber } from 'modules/lemmatize/lemmatizeWordAccordingToNumber';

interface AdvertisementCountDisplayProps {
    count: number;
}

const AdvertisementCountDisplay: FC<AdvertisementCountDisplayProps> = ({
    count,
}) => {
    const lemmatizedString = lemmatizeWordAccordingToNumber(count, {
        eqZero: 'nehnuteľností',
        eqOne: 'nehnuteľnosť',
        betweenTwoAndFourInclusive: 'nehnuteľnosti',
        moreThenFour: 'nehnuteľností',
    });

    const [formattedNumber, text] = lemmatizedString.split(' ', 2);
    const showCount = !!count && count > 0;

    return (
        <>
            {showCount && (
                <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                    flexWrap='wrap'
                >
                    <Text
                        center
                        semibold
                        variant='h5'
                        mobileVariant='body2'
                        color={(palette) => palette.labelPrimaryInverse}
                    >
                        Ponuka&nbsp;
                    </Text>
                    <Box
                        sx={{
                            background:
                                'linear-gradient(91deg, #E40046 -0.01%, #CE0F69 100.02%)',
                            borderRadius: 2,
                            padding: {
                                xs: '0 6px',
                                sm: '2px 8px',
                            },
                            display: 'inline-block',
                        }}
                    >
                        <Text
                            center
                            semibold
                            variant='h5'
                            mobileVariant='body2'
                            color={(palette) => palette.labelPrimaryInverse}
                        >
                            {formattedNumber}
                        </Text>
                    </Box>
                    <Text
                        center
                        semibold
                        variant='h5'
                        mobileVariant='body2'
                        color={(palette) => palette.labelPrimaryInverse}
                    >
                        &nbsp;{text}
                    </Text>
                </Box>
            )}
        </>
    );
};

export default AdvertisementCountDisplay;
