import React, { FC, useMemo } from 'react';
import dynamic from 'next/dynamic';
import { Box, Theme, useMediaQuery } from '@mui/material';
import { Link } from 'components/carousel/cssCarousel/components/partials/link/Link';
import {
    CarouselLoader
} from 'components/page/homePage/components/devProjects/list/partials/carousel/partials/loader/CarouselLoader';

const DevProjectsListCarousel = dynamic(
    () => import('./partials/carousel/DevProjectsListCarousel')
        .then((component) => component.DevProjectsListCarousel),
    {
        ssr: false,
        loading: () => <CarouselLoader/>
    }
);
interface IProps {
    items: JSX.Element[];
    fetchDevProjects: () => void;
}

export const DevProjectsList: FC<IProps> = ({ items, fetchDevProjects }) => {
    const wrappedItems = useMemo(() => {
        return items.map((item, index) => (
            <Link target='_blank' key={index} href={item.props.href}>
                {item}
            </Link>
        ));
    }, [items]);

    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('md')
    );

    return (
        <Box
            sx={{
                paddingTop: { xs: 6, md: 8 },
                paddingBottom: { xs: 4, md: 8 },
                paddingX: { md: 4, lg: 4 },
                borderRadius: { md: 5 },
                overflow: isMobile ? 'hidden' : 'visible',
            }}
            bgcolor={(theme) => theme.palette.backgroundSecondary.main}
        >
            <DevProjectsListCarousel
                items={wrappedItems}
                fetchDevProjects={fetchDevProjects}
            />
        </Box>
    );
};
