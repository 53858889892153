'use client';

import { FC } from 'react';
import { Grid2 } from '@mui/material';
import { IconBox } from 'components/page/homePage/components/whatAreYouLookingFor/partials/iconsBoxes/partials/iconBox/IconBox';
import { iconBoxes } from 'components/page/homePage/constants/config/whatAreYouLookingFor/iconBoxes/iconBoxes';
import { useApartmentsDialog } from 'components/page/homePage/components/whatAreYouLookingFor/partials/iconsBoxes/partials/iconBox/partials/dialogs/useApartmentsDialog';
import { useLandsDialog } from 'components/page/homePage/components/whatAreYouLookingFor/partials/iconsBoxes/partials/iconBox/partials/dialogs/useLandsDialog';
import { useObjectsAndSpacesDialog } from 'components/page/homePage/components/whatAreYouLookingFor/partials/iconsBoxes/partials/iconBox/partials/dialogs/useObjectsAndSpacesDialog';
import { ECategory } from 'components/page/homePage/enums/categorySefs/ECategory';

export const IconsBoxes: FC = () => {

    const {
        component: apartmentsModal,
        show: showApartmentsModal,
    } = useApartmentsDialog();

    const {
        component: landsModal,
        show: showLandsModal,
    } = useLandsDialog();

    const {
        component: objectsAndSpacesModal,
        show: showObjectsAndSpacesModal,
    } = useObjectsAndSpacesDialog();

    return (
        <>
            <Grid2 container columnSpacing={{
                xs: 1.5,
                md: 2,
            }}>
                {iconBoxes.map((box, index) => (
                    <Grid2 key={index} size={{ xs: 6, md: 2.4 }}>
                        <IconBox
                            sef={box.sef}
                            icon={box.icon}
                            link={box.link}
                            label={box.label}
                            mainLink={box.mainLink}
                            openModal={(label) => {
                                if (label === ECategory.APARTMENTS) {
                                    showApartmentsModal();
                                }
                                if (label === ECategory.SECONDARY_LANDS) {
                                    showLandsModal();
                                }
                                if (label === ECategory.SECONDARY_SPACES_AND_OBJECTS) {
                                    showObjectsAndSpacesModal();
                                }
                            }}
                        />
                    </Grid2>
                ))}
            </Grid2>
            {apartmentsModal}
            {landsModal}
            {objectsAndSpacesModal}
        </>
    );
};
