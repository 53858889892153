import { Box } from '@mui/system';
import React, { FC } from 'react';
import WelcomeImage from 'components/page/homePage/components/becomeMember/assets/WelcomeImage.png';
import Image from 'components/image/Image';
import { Text } from 'modules/theme/components/text/Text';
import { spinClockwiseKeyframes, sunriseRightMobileKeyframes } from 'components/page/homePage/components/becomeMember/constants/Keyframes';
import SunImage from 'components/page/homePage/components/becomeMember/assets/Sun.svg';
import { MY_ACCOUNT } from 'modules/route/routes';
import Link from 'components/link/Link';
interface IProps {
    name: string
}

export const MobileLoggedInBecomeMember: FC<IProps> = ({ name }) => {
    return (
        <>
            <Box display='flex' justifyContent='center' flexDirection='row' marginBottom={2}>
                <Text variant='h4' semibold>
                    Dobrý deň,&nbsp;
                </Text>
                <Link href={MY_ACCOUNT}>
                    <Text variant='h4' semibold underLine>
                        {name}
                    </Text>
                </Link>
            </Box>
            <Box display='flex' justifyContent='center' gap={2} marginBottom={5}>
                <Box>
                    <Box position='absolute' sx={{
                        animation: `${sunriseRightMobileKeyframes} 0.5s, ${spinClockwiseKeyframes} 1.25s infinite`,
                        animationDelay: '2s',
                        animationFillMode: 'forwards',
                        width: 35,
                        height: 35,
                        marginLeft: 3,
                        marginTop: 3,
                        zIndex: -1,
                    }}>
                        <SunImage width={35} height={35} />
                    </Box>
                    <Image
                        style={{
                            objectFit: 'contain',
                        }}
                        src={WelcomeImage}
                        alt='welcomeImage'
                        placeholder='blur'
                        height={60}
                        width={80}
                    />
                </Box>
                <Box display='flex' flexDirection='column'>
                    <Text variant='body2'>
                        Ďakujeme, že sme váš obľúbený
                    </Text>
                    <Text variant='body2'>
                        realitný portál.
                    </Text>
                    <Text variant='body2' semibold>
                        Aké možnosti dnes využijete?
                    </Text>
                </Box>
            </Box>
        </>
    );
};