'use client';

import { FC, useEffect, useMemo } from 'react';
import { Container } from '@mui/material';
import useAnalytics from 'modules/stores/analytics/useAnalytics';
import { DevProjectsList } from 'components/page/homePage/components/devProjects/list/DevProjectsList';
import { useDevProjects } from 'components/page/homePage/components/devProjects/common/useDevProjects';
import { ECategoryLabelRecord } from 'components/page/homePage/components/devProjects/common/helpers/ECategoryLabelRecord';
import { PromoDevProjectCard } from 'modules/theme/components/listing/cards/promoDevProjectCard/components/card/PromoDevProjectCard';
import { getResetEcommerceEvent } from 'modules/gtmEvents/helpers/events/common/commonEventsHelper';
import {
    getPromoDevProjectsEvent,
    getSelectItemEvent,
    getSelectPromotionEvent,
    getViewDevProjectsEvent
} from 'modules/gtmEvents/helpers/events/homePage/homePageEventsHelper';
import { IHomePageDevProject } from 'modules/api/clients/homePage/interfaces/devProjects/IHomePageDevProject';

interface IDevProjects {
    devProjectsInitial: IHomePageDevProject[];
}

export const DevProjects: FC<IDevProjects> = ({
    devProjectsInitial,
}) => {
    const { devProjects, fetchDevProjects, isFetching } = useDevProjects(devProjectsInitial);
    const { gtm: { sendEvent: gtmSendEvent }} = useAnalytics();

    const items = useMemo(() => {
        return devProjects?.map((devProject, index) => {
            return (
                <PromoDevProjectCard
                    key={devProject.id}
                    devProject={{
                        price: {
                            price: devProject.priceInfo.price,
                            unitPrice: devProject.priceInfo.unitPrice
                        },
                        projectId: devProject.id,
                        imageUrl: devProject.photoUrl,
                        location: devProject.location?.name,
                        packages: {
                            isPremium: devProject.flags.isPremium,
                            isTop: devProject.flags.isTop
                        },
                        projectName: devProject.title,
                        projectSlugName: devProject.sefName,
                        totalAdvertisementsCount: devProject.advertisementInfo.totalCount,
                        advertisementsCategories: [ECategoryLabelRecord[devProject.advertisementInfo.category]]
                    }}
                    devProjectDetailUrl={devProject.detailUrl}
                    onProjectClick={() => {
                        gtmSendEvent(
                            getResetEcommerceEvent(),
                            getSelectPromotionEvent(devProject, index),
                            getSelectItemEvent(devProject, index)
                        );
                    }}
                />
            );
        });
    }, [devProjects, gtmSendEvent]);

    useEffect(() => {
        if (devProjects?.length === 0) return;
        if (isFetching) return;

        gtmSendEvent(
            getResetEcommerceEvent(),
            getPromoDevProjectsEvent(devProjects),
            getViewDevProjectsEvent(devProjects)
        );
    }, [isFetching, devProjects, gtmSendEvent]);

    return (
        <Container maxWidth='xl' disableGutters>
            <DevProjectsList items={items} fetchDevProjects={fetchDevProjects}/>
        </Container>
    );
};
